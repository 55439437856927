<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{desc3}}</p>
                        <p v-b-modal.modal-3 variant="link" class="mb-0"><u>판매내역 수집 방법</u></p>
                            <b-modal id="modal-3" size="xl" title="판매내역 수집 방법" ok-title="Save Changes" cancel-title="Close">
                                <p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 <b style="color:red"><u>"Supplier"에 로그인한 상태에서</u></b> 판매내역 수집 버튼을 클릭해주세요.<br> 판매내역 수집 예상시간이 끝날때까지 창을 닫거나 조정하시면 안됩니다.(SKU당 약0.2초소요)</p>
                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/extension_sales.png')">
                            </b-modal>
                    </div>
                </div>
			</div>
			<card class="col-lg-12">
				<div class="p-3">
					<form  @submit.prevent="submit" >
						<div class="row">
							<!-- <div class="col-md-4">
								<div class="form-group">
									<label class="ml-1">SKU ID</label>
									<input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID" style="height:60px">
								</div>
							</div> -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="ml-1">조회날짜(시작일)</label>
									<b-form-datepicker id="example-datepicker1" v-model="startdate" class="mb-2"></b-form-datepicker>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label class="ml-1">조회날짜(종료일)</label>
									<b-form-datepicker id="example-datepicker2" v-model="enddate" class="mb-2"></b-form-datepicker>
								</div>
							</div>

						</div>
						<button type="button" class="btn btn-primary mr-2 mt-1" @click="search">검색</button>

						<img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
					</form>
				</div>
			</card>

			<div class="col-lg-12" v-if="showlist">
				<div class="row">
					<b-button variant="outline-success" class="mb-1 mr-2" @click="showtotal">
					총 상품수<b-badge variant="success" class="ml-2">{{productqty}}</b-badge>
					</b-button>
					<b-button variant="outline-primary" class="mb-1 mr-2" @click="showsalescnt">
					총 판매수량<b-badge variant="primary" class="ml-2">{{salescnt}}</b-badge>
					</b-button>
					<b-button variant="outline-warning" class="mb-1 mr-2" @click="showimportcnt">
					총 입고수량<b-badge variant="warning" class="ml-2">{{importcnt}}</b-badge>
					</b-button>
					<b-button variant="outline-success" class="mb-1 mr-2" @click="showsalesamount">
					총 판매매출(쿠팡)<b-badge variant="success" class="ml-2">{{salesamount}}</b-badge>
					</b-button>
					<b-button variant="outline-danger" class="mb-1 mr-2" @click="showsupplyamount">
					총 공급매출(서플라이어)<b-badge variant="danger" class="ml-2">{{supplyamount}}</b-badge>
					</b-button>
				</div>
				<div class="row">
					<b-button variant="outline-secondary" class="mb-1 mr-2" @click="shownoreview">
					리뷰 없음<b-badge variant="secondary" class="ml-2">{{noreview}}</b-badge>
					</b-button>
					<b-button variant="outline-danger" class="mb-1 mr-2" @click="shownostock">
					재고 없음<b-badge variant="danger" class="ml-2">{{nostock}}</b-badge>
					</b-button>
					<b-button variant="outline-warning" class="mb-1 mr-2" @click="showitemloser">
					아이템위너 미선정<b-badge variant="warning" class="ml-2">{{itemloser}}</b-badge>
					</b-button>
				</div>
			</div>

			<div class="col-lg-12 mt-3" v-if="showlist">
				<div class="table-responsive rounded mb-3">
                    <CDataTable :headers="headers" :items="saleslists">
                        <template v-slot:판매상품명="{item}">
                            <div style="display: flex; align-items: center; padding: 10px 0px; line-height: 2" v-b-modal="modalID(item.SKUID)">
                                <div>
                                    <img :src="item.썸네일" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                </div>
                                <div>
                                    <div style="color: #1da1f2; text-decoration: underline">{{item.판매상품명}}</div>
                                    <p class="mb-0" style="color: #676E8A;"><small>{{item.SKUID}}</small></p>
                                    <p class="mb-0" style="color: #676E8A;"><small>{{item.바코드}}</small></p>
                                </div>
                            </div>
                            <b-modal :id="'modal'+item.SKUID" scrollable title="판매세부내역" ok-title="OK" cancel-title="Close" class="detail-modal">
                                <div class="row">
                                    <div class="col-12">
                                        <card style="padding:10px;">
                                            <div class="d-flex align-items-center" style="margin:auto;padding:0px">
                                                <img :src="item.썸네일" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                <div>
                                                    판매상품명 : {{item.판매상품명}}
                                                    <p class="mb-0"><small>SKU: {{item.SKUID}}</small></p>
                                                    <p class="mb-0"><small>바코드: {{item.바코드}}</small></p>
                                                </div>
                                            </div>
                                        </card>
                                    </div>
                                    <div class="col-12">
                                        <CDataTable :headers="innerHeader" :items="item.판매세부내역">
                                            <template v-slot:판매링크="{item}">
                                                <a :href="item.판매링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a>
                                            </template>
                                        </CDataTable>
										<button type="button" class="btn btn-primary mt-2" @click="downloadsalesDetail(item.판매세부내역)">다운로드</button>
                                    </div>
                                </div>
                            </b-modal>
                        </template>
                        <template v-slot:아이템위너="{item}">
                            <div>
                                {{ item.아이템위너 ? '선정' : '미선정' }}
                            </div>
                        </template>
                        <template v-slot:판매링크="{item}">
                            <div>
                                <a :href="item.판매링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a>
                            </div>
                        </template>
                    </CDataTable>
				</div>
            <button type="button" class="btn btn-primary mr-2 mb-4" @click="downloadsales">다운로드</button>
			<button type="button" class="btn btn-success mr-2 mb-4" @click="salesdownload">일자별 판매내역 다운로드</button>
			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
// import { splice } from 'core-js/internals/enum-bug-keys';
import * as XLSX from 'xlsx';
import CDataTable from "@/components/common/CDataTable";
// import { json } from 'body-parser';
export default {
	name:'salesstatus',
    components:{
        CDataTable
    },
	data(){
		return{
            headers: [
                {text: '상품정보', value: '판매상품명', align: 'center', width: 400, isSlot: true},
                {text: '발주가능상태', value: '발주가능상태', align: 'left', width: 120, isSlot: false},
                {text: '아이템위너', value: '아이템위너', align: 'left', width: 120, isSlot: true},
                {text: '쿠팡내재고', value: '재고', align: 'left', width: 120, isSlot: false},
                {text: '판매', value: '판매', align: 'left', width: 100, isSlot: false},
                {text: '입고', value: '입고', align: 'left', width: 100, isSlot: false},
                {text: '판매가', value: '판매가', align: 'left', width: 100, isSlot: false},
                {text: '판매매출', value: '판매매출', align: 'left', width: 100, isSlot: false},
                {text: '공급매출', value: '공급매출', align: 'left', width: 100, isSlot: false},
                {text: '리뷰수', value: '리뷰수', align: 'left', width: 100, isSlot: false},
                {text: '리뷰평점', value: '리뷰평점', align: 'left', width: 100, isSlot: false},
                {text: '판매링크', value: '판매링크', align: 'left', width: 100, isSlot: true},
            ],
            innerHeader: [
                {text: 'Date', value: 'date', align: 'left', width: 100, isSlot: false},
                {text: '발주가능상태', value: '발주가능상태', align: 'left', width: 120, isSlot: false},
                {text: '쿠팡내재고', value: '재고', align: 'left', width: 120, isSlot: false},
                {text: '판매', value: '판매', align: 'left', width: 100, isSlot: false},
                {text: '입고', value: '입고', align: 'left', width: 100, isSlot: false},
                {text: '판매가', value: '판매가', align: 'left', width: 100, isSlot: false},
                {text: '판매매출', value: '판매매출', align: 'left', width: 100, isSlot: false},
                {text: '공급매출', value: '공급매출', align: 'left', width: 100, isSlot: false},
                {text: '리뷰수', value: '리뷰수', align: 'left', width: 100, isSlot: false},
                {text: '리뷰평점', value: '리뷰평점', align: 'left', width: 100, isSlot: false},
                {text: '판매링크', value: '판매링크', align: 'left', width: 100, isSlot: true},
            ],
        // <th>Date</th>
        // <th>발주가능상태</th>
        // <th>쿠팡내재고</th>
        // <th>판매</th>
        // <th>입고</th>
        // <th>판매가</th>
        // <th>판매매출</th>
        // <th>공급매출</th>
        // <th>리뷰수</th>
        // <th>리뷰평점</th>
        // <th>판매링크</th>
			salesarr:[],
			importarr:[],
			stockarr:[],

			salescnt:0,
			importcnt:0,
			salesamount:0,
			supplyamount:0,
			noreview:0,
			productqty:0,
			nostock:0,
			itemloser:0,
			// increview:0,
			// decreview:0,


			title:"판매(재고)현황",
			desc1:"Chrome 웹스토어에서 COUPLUS Extension을 다운받은 후 판매내역을 수집해주세요.",
			desc2:"2일 이상 수집 및 기간조회를 하면 판매량 확인이 가능합니다. (검색 시 경고창이 뜨는 경우 '대기'를 눌러주세요)",
			desc3:"발주가능상태가 정상이 아니거나, 일시품절, 공급 사업자가 바뀐 경우 데이터가 출력되지 않을 수 있습니다.",
			link:{ name: 'people.addusers'},
			linktext:"판매내역수집방법",

			SKUID:'',
			showspinner: false,
			startdate: this.getYesterday(),
			enddate: this.getTodaywith(),
			saleslists: [],
			saleslistsinit: [],
			showlist: false,


			UserListS:[
				{
					name:'Cliff Hanger',
					email:'cliff@gmail.com',
					company:'Product Manager',
					group:'Cliff',
					status:'Active',
					store:'1'
				},
				{
					name:'Terry Aki',
					email:'terry@gmail.com',
					company:'Stock CEO',
					group:'Terry',
					status:'Active',
					store:'2'
				}
			]
		}
	},
	methods: {
		adjust(){
			//판매세부내역 입고보정
			for(var j=0;j<this.saleslists.length;j++){
				var e = this.saleslists[j].판매세부내역;
				for(var i=0;i<e.length-1;i++){
					if(e[i].입고 > 0 && e[i+1].판매 < 0){
						e[i+1].입고 = e[i].입고;
						e[i+1].판매 = e[i].재고 - (e[i+1].재고 - e[i+1].입고);
						e[i+1].판매매출 = e[i+1].판매*e[i].판매가;
						e[i+1].공급매출 = e[i+1].판매*e[i].공급가;

						e[i].입고 = 0;
						if(i>0){
							e[i].판매 = e[i-1].재고 - e[i].재고;
							e[i].판매매출 = e[i].판매*e[i].판매가;
							e[i].공급매출 = e[i].판매*e[i].공급가;
						}
					}
				}
			}


			//saleslists 판매, 판매매출, 공급매출 조정

			//총 판매수량, 총 판매매출, 총 공급매출 조정
		},
		showitemloser(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => !e.아이템위너);
			setTimeout(()=>{this.showlist = true;},10)
		},
		shownostock(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.재고 == 0);
			setTimeout(()=>{this.showlist = true;},10)
		},
		showtotal(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit;
			setTimeout(()=>{this.showlist = true;},10)
		},
		showsupplyamount(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.공급매출 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showsalesamount(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.판매매출 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showimportcnt(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.입고 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showsalescnt(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.판매 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		shownoreview(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.리뷰수 == 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		salesdownload(){
			if(this.salesarr.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			for(var i=0;i<this.salesarr.length;i++){
				// console.log(i)
				this.salesarr[i]['총합계'] = 0;
				this.stockarr[i]['총합계'] = 0;
				this.importarr[i]['총합계'] = 0;
				Object.keys(this.salesarr[i]).forEach(e => {
					if(e !== 'SKUID' && e !== '바코드' && e !== '판매상품명' && e !== '총합계'){
						this.salesarr[i].총합계 += parseInt(this.salesarr[i][e]);
						this.stockarr[i].총합계 += parseInt(this.salesarr[i][e]);
						this.importarr[i].총합계 += parseInt(this.salesarr[i][e]);
					}
				})
			}
			this.salesarr.sort(function(a,b){
				if (a.총합계 < b.총합계){
					return 1;
				}
				if (a.총합계 > b.총합계){
					return -1;
				}
				return 0;
			})
			this.stockarr.sort(function(a,b){
				if (a.총합계 < b.총합계){
					return 1;
				}
				if (a.총합계 > b.총합계){
					return -1;
				}
				return 0;
			})
			this.importarr.sort(function(a,b){
				if (a.총합계 < b.총합계){
					return 1;
				}
				if (a.총합계 > b.총합계){
					return -1;
				}
				return 0;
			})

			this.stockarr.forEach(e => {
				delete e.총합계
			})
			this.importarr.forEach(e => {
				delete e.총합계
			})
			const workBook = XLSX.utils.book_new()
			var myHeader = Object.keys(this.salesarr[0]);
			var myHeader2 = myHeader.filter(e => e !== '총합계');
			const workSheet1 = XLSX.utils.json_to_sheet(this.salesarr, {header : myHeader})
			XLSX.utils.book_append_sheet(workBook, workSheet1, '판매현황')
			const workSheet2 = XLSX.utils.json_to_sheet(this.stockarr, {header : myHeader2})
			XLSX.utils.book_append_sheet(workBook, workSheet2, '재고현황')
			const workSheet3 = XLSX.utils.json_to_sheet(this.importarr, {header : myHeader2})
			XLSX.utils.book_append_sheet(workBook, workSheet3, '입고현황')
			XLSX.writeFile(workBook, '일자별판매현황' + '_' + this.startdate + '-' + this.enddate + '.xlsx')
		},
		search(){
			if(this.startdate == this.enddate){
				if(!confirm('시작일과 종료일이 동일하면 판매량 확인이 되지 않습니다. 그래도 계속해서 조회하시겠습니까?')){
					return
				}
			}
			this.showspinner = true;
			var start = this.startdate;
			var end = this.enddate;
			// var start = (this.timestamp(this.startdate)*1000) - (60*60*1000*9);
			// var end = (this.timestamp(this.enddate)*1000) + (60*60*15*1000 - 1);
			axios.post('/api/listsales',{companyid:this.$store.state.user.companyid, start:start, end:end})
			.then(result => {
				// console.log(result.data.sales.length)
				// var data = JSON.parse(result.data.slice(0,-1) + "]")
				// console.log(data)
				this.$store.commit('salesupdate', result.data.sales);
				axios.post('/api/listsales/supplylist',{companyid:this.$store.state.user.companyid, start:start, end:end})
				.then(result2 => {
					this.$store.commit('supplyupdate', result2.data.supply);
					this.searchresult()
				})
			})
		},
		datetotimestamp(a){
			var date = new Date(a);
			return date.getTime();
		},
		searchresult(){
			this.saleslists = [];
			this.salesarr = [];
			this.saleslistsinit = [];
			this.showlist = false;
			var obj = {};
			var d = {};
			// var 이전재고 = '';
			// var t = {};
			// var su = {};
			// var 입고수량 = 0;
			var sales = [];
			this.$store.state.sales.forEach(e => {
				sales.push(this.deepClone(e))
			})
			var supply = [];
			this.$store.state.supply.forEach(e => {
				supply.push(this.deepClone(e))
			})
			// var productdb = this.$store.state.productdb.filter(e => !e.등록이미지?.includes('vendor_inventory'));
			// 제트제품중 로켓으로 이관된 제품이 있어서 모두 노출로 변경함.

			var productdb = this.$store.state.productdb;
			// console.log(productdb)
			// console.log(this.$store.state.productdb.find(e => e.바코드 == 'R037956923120'))
			if(this.$store.state.sales.length > 0){
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				var dates = [];
				sales.forEach(e => {
					if(!dates.find(ele => ele.date == e.date)){
						dates.push({date : e.date})
					}
				})
				supply.forEach(e => {
					if(!dates.find(ele => ele.date == e.importdate) && dates[0].date < e.importdate){
						dates.push({date : e.importdate})
					}
				})
				// console.log(dates)
				dates.sort(function(a,b){
					if (a.date > b.date){
						return 1;
					}
					if (a.date < b.date){
						return -1;
					}
					return 0;
				})
				productdb.sort(function(a,b){
					if (a["SKU ID"] > b["SKU ID"]){
						return 1;
					}
					if (a["SKU ID"] < b["SKU ID"]){
						return -1;
					}
					return 0;
				})
				sales.forEach(e => {
					e.판매세부내역.sort(function(a,b){
						if (a.sk > b.sk){
							return 1;
						}
						if (a.sk < b.sk){
							return -1;
						}
						return 0;
					})
				})
				supply.forEach(e => {
					e.importlist.sort(function(a,b){
						if (a.sku번호 > b.sku번호){
							return 1;
						}
						if (a.sku번호 < b.sku번호){
							return -1;
						}
						return 0;
					})
				})



				for(var i=0;i<productdb.length;i++){
					var pred = {
						재고 : 0,
						입고 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매가 : 0,
						공급가 : 0,
						아이템위너 : '',
					}
					obj = {
						썸네일 : productdb[i].썸네일,
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드,
						발주가능상태 : productdb[i].발주가능상태,
						재고 : 0,
						판매 : 0,
						입고 : 0,
						판매가 : 0,
						공급가 : 0,
						판매매출 : 0,
						공급매출 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매링크 : productdb[i].판매링크,
						판매세부내역 : []
					}
					var obsales = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obstock = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obimport = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					dates.forEach((e,k) => {

						d = {
							date : e.date,
							발주가능상태 : productdb[i].발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							공급가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : productdb[i].판매링크,
							재고확인시간 : 0,
							아이템위너 : '',
						}
						// console.log(e)
						// console.log(sales.findIndex(ele => ele.date == e.date))
						if(sales.findIndex(ele => ele.date == e.date) >= 0){
							var j = sales.findIndex(ele => ele.date == e.date)
							// console.log(j)
							for(var n=0;n<sales[j].판매세부내역.length;n++){

								if(parseInt(sales[j].판매세부내역[n].sk) == parseInt(productdb[i]["SKU ID"])){
									// if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
									// 	console.log(e)
									// 	console.log(sales[j].판매세부내역[n])
									// }
									d.재고 = sales[j].판매세부내역[n].s === 0 || sales[j].판매세부내역[n].s > 0 ? parseInt(sales[j].판매세부내역[n].s) : sales[j].판매세부내역[n].s < 0 ? 0 : pred.재고;
									d.리뷰수 = sales[j].판매세부내역[n].rq === 0 || sales[j].판매세부내역[n].rq ? parseInt(sales[j].판매세부내역[n].rq) : pred.리뷰수;
									d.리뷰평점 = sales[j].판매세부내역[n].rp === 0 || sales[j].판매세부내역[n].rp ? parseFloat(sales[j].판매세부내역[n].rp) : pred.리뷰평점;
									d.판매가 = sales[j].판매세부내역[n].p === 0 || sales[j].판매세부내역[n].p ? parseFloat(sales[j].판매세부내역[n].p) : pred.판매가;
									d.재고확인시간 = sales[j].time ? sales[j].time : 0;
									d.아이템위너 = sales[j].판매세부내역[n].w === true ? sales[j].판매세부내역[n].w : pred.아이템위너;
									sales[j].판매세부내역.splice(n,1)
									// console.log(sales[j].판매세부내역.length)
									break;
								}
								if(parseInt(sales[j].판매세부내역[n].sk) > parseInt(productdb[i]["SKU ID"])){
									d.재고 = pred.재고;
									d.리뷰수 = pred.리뷰수;
									d.리뷰평점 = pred.리뷰평점;
									d.판매가 = pred.판매가;
									d.공급가 = pred.공급가;
									d.아이템위너 = pred.아이템위너;
									break;
								}
							}
						} else {
							d.재고 = pred.재고;
							d.리뷰수 = pred.리뷰수;
							d.리뷰평점 = pred.리뷰평점;
							d.판매가 = pred.판매가;
							d.공급가 = pred.공급가;
							d.아이템위너 = pred.아이템위너;
						}
						// if(productdb[i]["SKU ID"] === 18462723){
						// 	console.log(d.재고)
						// }
						if(supply.findIndex(ele => ele.importdate == e.date) >= 0){
							j = supply.findIndex(ele => ele.importdate == e.date)
							for(n=0;n<supply[j].importlist.length;n++){
								// console.log(supply[j].importlist[n])
								// console.log(supply[j].importlist[n].sku번호)
								if(parseInt(supply[j].importlist[n].sku번호) == parseInt(productdb[i]["SKU ID"])){
									// if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
									// 	console.log(e)
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// 	console.log(d.재고확인시간)
									// 	console.log(this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// 	console.log(d.재고확인시간 < this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// }
									d.입고 += parseInt(supply[j].importlist[n].수량);
									if(d.재고확인시간 <= this.datetotimestamp(supply[j].importlist[n]['입고/반출일자'])){
										d.재고 += parseInt(supply[j].importlist[n].수량);
									}
									// if(productdb[i]["SKU ID"] === 18462723){
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// }
								}
								if(parseInt(supply[j].importlist[n].sku번호) > parseInt(productdb[i]["SKU ID"])){
									// d.입고 = pred.입고;
									// d.재고 = pred.재고;
									break;
								}
							}
						}
						if(k == 0){
							d.판매 = 0;
						} else {
							d.판매 = pred.재고 - d.재고 + d.입고;
						}
						d.판매매출 = d.판매가 * d.판매;
						d.공급매출 = productdb[i].매입가 ? productdb[i].매입가 * d.판매 : productdb[i].현재매입가 ? productdb[i].현재매입가 * d.판매 : 0;
						d.공급가 = productdb[i].매입가 ? productdb[i].매입가 : productdb[i].현재매입가 ? productdb[i].현재매입가 : 0;
						pred = d;
						obj.판매세부내역.push(d)
						obj.공급가 = d.공급가;
						obj.재고 = d.재고;
						obj.판매 += d.판매;
						obj.입고 += d.입고;
						obj.판매매출 += d.판매매출;
						obj.공급매출 += d.공급매출;
						obj.판매가 = d.판매가;
						obj.리뷰수 = d.리뷰수;
						obj.리뷰평점 = d.리뷰평점;
						obj.아이템위너 = d.아이템위너;

						obsales[e.date] = d.판매;
						obimport[e.date] = d.입고;
						obstock[e.date] = d.재고;
					})
					this.salesarr.push(obsales);
					this.stockarr.push(obstock);
					this.importarr.push(obimport);

					this.saleslists.push(this.deepClone(obj))
					this.saleslistsinit.push(this.deepClone(obj))
				}


				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)

				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;
				this.itemloser = this.saleslists.filter(e => !e.아이템위너).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				this.adjust()
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 10);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}
			console.log(this.salesarr)
			console.log(this.saleslists)
		},
		searchresult2(){
			this.saleslists = [];
			this.showlist = false;
			var obj = {};
			var d = {};
			var 이전재고 = '';
			var t = {};
			var su = {};
			var 입고수량 = 0;
			if(this.$store.state.sales.length > 0){
				this.$store.state.sales.sort(function(a,b){
					if (a.date < b.date){
						return -1;
					}
					if (a.date > b.date){
						return 1;
					}
					return 0;
				})
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				var dates = [];
				this.$store.state.sales.forEach(e => {
					if(!dates.find(ele => ele == e.date)){
						dates.push(e.date)
					}
				})
				this.$store.state.supply.forEach(e => {
					if(!dates.find(ele => ele == e.importdate)){
						dates.push(e.importdate)
					}
				})
				dates.sort(function(a,b){
					if (a > b){
						return 1;
					}
					if (a < b){
						return -1;
					}
					return 0;
				})
				this.$store.state.productdb.forEach((e,k) => {
					console.log(k + '/' + this.$store.state.productdb.length)
					if(this.$store.state.sales.filter(ele => ele.판매세부내역.find(element => element.sk == e["SKU ID"])).length == this.$store.state.sales.length){
						obj = {
							썸네일 : e.썸네일,
							판매상품명 : e.판매상품명,
							SKUID : e["SKU ID"],
							바코드 : e.바코드,
							발주가능상태 : e.발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : e.판매링크,
							판매세부내역 : []
						}
						var ob = {
							'SKU ID':e['SKU ID'],
							바코드 : e.바코드,
							상품명 : e.상품명,
						}

						if(this.$store.state.sales.length > 1){
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								//console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									//console.log(su)
									if(su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).length > 0){
										입고수량 = su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).reduce((pv,rv) => {return pv + parseInt(rv.수량)},0)
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								if(이전재고 == ''){
									이전재고 = t.s ? t.s : 0;
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s+입고수량 ? t.s+입고수량 : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								이전재고 = d.재고 ? d.재고 : 0;
							})
							var salesqty = 0
							ob[this.$store.state.sales[0].date] = 0;
							for (var j=1;j<obj.판매세부내역.length;j++){
								salesqty = obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 ? obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 : 0;
								obj.판매세부내역[j].판매 = salesqty ? salesqty : 0;
								obj.판매 += salesqty ? salesqty : 0;
								obj.판매세부내역[j].판매매출 = salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매매출 += salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매세부내역[j].공급매출 = salesqty*e.매입가 ? salesqty*e.매입가 : 0;
								obj.공급매출 += salesqty*e.매입가 ? salesqty*e.매입가 : 0;
								ob[obj.판매세부내역[j].date] = salesqty ? salesqty : 0;
							}
							this.salesarr.push(ob)
						} else {
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								// console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									// console.log(su)
									if(su.importlist.find(element => element.sku번호.toString() == t.sk.toString())){
										입고수량 = parseInt(su.importlist.find(element => element.sku번호.toString() == t.sk.toString()).수량);
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s ? t.s : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								ob[ele.date] = 0;
								this.salesarr.push(ob)
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.판매 = 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								obj.판매매출 = 0;
								obj.공급매출 = 0;
								setTimeout(() => {이전재고 = d.재고 ? d.재고 : 0},1)
							})
						}
						this.saleslists.push(this.deepClone(obj))
						this.saleslistsinit.push(this.deepClone(obj))
					}
				})

				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}
			console.log(this.salesarr)
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
		todate(date){
			var today = new Date(date);
			today.setHours(today.getHours() + 9);
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getDatewith(d){
			var date = new Date(d);
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getYesterday(){
			return this.getDatewith(this.datetotimestamp(this.getTodaywith()) - 1000*60*60*24)
		},
		downloadsales() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(this.saleslists)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '판매내역')
			XLSX.writeFile(workBook, '판매내역' + '_' + getToday() + '.xlsx')
		},
		downloadsalesDetail(판매세부내역) {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(판매세부내역)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '판매내역')
			XLSX.writeFile(workBook, '판매내역' + '_' + getToday() + '.xlsx')
		}
	}

}
</script>

<style>
.my-class .dropdown-menu {

max-height: 300px;
width:800px;
overflow-y: auto;
}
.modal-dialog {
    max-width: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
